import React, { Component } from 'react';

import { 
  //Container, 
  Row, Col } from 'reactstrap';

class PartFooter extends Component {
  render() {
    return (
      <footer>
        <Row>
          <Col md="12">
            <p>©{(new Date().getFullYear())} FARMINGMINING.com</p>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default PartFooter;