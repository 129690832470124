import React, { Component } from 'react';
import cookie from 'react-cookies';

import { Container, Row, Col } from 'reactstrap';

class PartContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    let heightTmp = window.innerWidth * 0.6;
    this.setState({ width: window.innerWidth, height: heightTmp });
  }

  render() {
    var translations;
    if(this.state.lang === undefined)
    {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if(languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz' || languageWithoutRegionCode === 'ru')
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' })
      }
    }
    else {
      translations = require('./lang/' + this.state.lang + '.json');
    }

    return (
      <div className="contactContainer" id="contact" name="contact">
      <section style={{background: 'url(./images/pageContact.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <h1>{translations.p7.Headline}</h1>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p7.Intro1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>FARMING MINING.com</p>
          </Col>
          <Col md="12">
            <p><a href="mailto:info@farmingmining.com">info@farmingmining.com</a></p>
          </Col>
          <Col md="12">
Miletičová 44
<br />
821 08, Bratislava II
          </Col>
        </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default PartContact;