import React, { Component } from 'react';
import cookie from 'react-cookies';

import { Container, Row, Col, Button, Collapse } from 'reactstrap';

import * as FontAwesome from 'react-icons/lib/fa';

import * as translations from './lang/sk.json';

class PartKrypto extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      width: 0,
      heightSet: true,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    let heightTmp = window.innerWidth * 0.6;
    this.setState({ width: window.innerWidth, height: heightTmp });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  
  render() {
    var translations;
    var documentUrl;
    if(this.state.lang === undefined)
    {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if(languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz') {
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
        documentUrl = "./documents/Dotaznik_" + languageWithoutRegionCode + "_Krypto_FARMING_MINING.pdf";
      } else if (languageWithoutRegionCode === 'ru') {
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
        documentUrl = "./documents/Анкета_" + languageWithoutRegionCode + "_Крипто_FARMING_MINING.pdf";
      } else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' })
        documentUrl = "./documents/Dotaznik_sk_Krypto_FARMING_MINING.pdf";
      }
    }
    else {
      if(this.state.lang === 'sk' || this.state.lang === 'cz') {
        translations = require('./lang/' + this.state.lang + '.json');
        documentUrl = "./documents/Dotaznik_" + this.state.lang + "_Krypto_FARMING_MINING.pdf";
      } else if (this.state.lang === 'ru') {
        translations = require('./lang/' + this.state.lang + '.json');
        documentUrl = "./documents/Анкета_" + this.state.lang + "_Крипто_FARMING_MINING.pdf";
      }
    }

    return (
      <div className="textContainer" id="crypto" name="crypto">
        <section style={{background: 'url(./images/pageKrypto.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
          <Container>
            <Row>
              <Col md="12">
                <FontAwesome.FaBitcoin size={28} />
                <h1>{translations.p3.Headline}</h1>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <p>{translations.p3.Intro1}</p>
                <p>{translations.p3.Intro2}</p>
                <p>
                  <Button color="danger" href={ documentUrl } download><FontAwesome.FaPaperclip size={24} /> {translations.p8.Text2}</Button>
                  &nbsp;
                  <Button color="warning" onClick={this.toggle}>{translations.p8.Text1}</Button>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <Collapse isOpen={this.state.collapse}>
        <Container>
          <Row>
            <Col md="4">
              <FontAwesome.FaHeader size={28} />
              <h3>{translations.p3.Part1.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part1.Text1}</p>
              <p>{translations.p3.Part1.Text2}</p>
              <p>{translations.p3.Part1.Text3}</p>
              <p>{translations.p3.Part1.Text4}</p>
              <p>{translations.p3.Part1.Text5}</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FontAwesome.FaMoney size={28} />
              <h3>{translations.p3.Part2.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part2.Text1}</p>
              <p>{translations.p3.Part2.Text2}</p>
              <p>{translations.p3.Part2.Text3}</p>
              <p>{translations.p3.Part2.Text4}</p>
              <p>{translations.p3.Part2.Text5}</p>
              <p>{translations.p3.Part2.Text6}</p>
              <p>{translations.p3.Part2.Text7}</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>{translations.p3.Part3.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part3.Text1}</p>
              <p>{translations.p3.Part3.Text2}</p>
              <p>{translations.p3.Part3.Text3}</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>{translations.p3.Part4.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part4.Text1}</p>
              <p>{translations.p3.Part4.Text2}</p>
              <p>{translations.p3.Part4.Text3}</p>
              <p>{translations.p3.Part4.Text4}</p>
              <p>{translations.p3.Part4.Text5}</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>{translations.p3.Part5.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part5.Text1}</p>
              <p>{translations.p3.Part5.Text2}</p>
              <p>{translations.p3.Part5.Text3}</p>
              <p>{translations.p3.Part5.Text4}</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>{translations.p3.Part6.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part6.Text1}</p>
              <p>{translations.p3.Part6.Text2}</p>
              <p>{translations.p3.Part6.Text3}</p>
              <p>{translations.p3.Part6.Text4}</p>
              <p>{translations.p3.Part6.Text5}</p>
              <p>{translations.p3.Part6.Text6}</p>
              <p>{translations.p3.Part6.Text7}</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FontAwesome.FaCubes size={28} />
              <h3>{translations.p3.Part7.Headline}</h3>
            </Col>
            <Col md="8">
              <p>{translations.p3.Part7.Text1}</p>
              <p>{translations.p3.Part7.Text2}</p>
              <p>{translations.p3.Part7.Text3}</p>
              <p>{translations.p3.Part7.Text4}</p>
              <p>{translations.p3.Part7.Text5}</p>
              <p>{translations.p3.Part7.Text6}</p>
              <p>{translations.p3.Part7.Text7}</p>
            </Col>
          </Row>
        </Container>
          </Collapse>
      </div>
    );
  }
}

export default PartKrypto;